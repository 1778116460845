import {Component} from 'vue-property-decorator'
import {I18nComponent} from '@/components/I18nComponent'
import {VNode} from 'vue'

@Component({name: 'Accounts'})
export default class extends I18nComponent {

  public render(): VNode {
    const warn = 'Page currently unavailable'
    return(
      <div class="text-center text-danger">
        <span>{warn}</span>
      </div>
    )
  }
}
